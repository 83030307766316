export function PieChartData(buckets) {
  const series = [];
  const labels = [];

  buckets.forEach((b) => {
    series.push(b.doc_count);
    labels.push(`${b.key} (${b.doc_count})`);
  });

  return {labels, series};
}

export const PieOptions = {
  title: {
    text: undefined,
    align: 'left',
    margin: 10,
    offsetX: 0,
    offsetY: 0,
    floating: false,
    style: {
      fontSize: '14px',
      fontWeight: 'bold',
      fontFamily: undefined,
      color: '#263238',
    },
  },
};
