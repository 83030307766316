import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Button } from 'react-bootstrap';

export const Profile = () => {
  const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  async function getAccessToken() {
    const at = await getAccessTokenSilently({authorizationParams: {
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: 'openid'      
    }});
    console.info('access token', at);
  }

  async function getIdToken() {
    const id = (await getIdTokenClaims()).__raw;
    console.info('id token', id);
  }

  if (!user) return null;

  return (
    <div>
      <Button type="button" onClick={() => getAccessToken()}>
        Test Access Token
      </Button>
      <Button type="button" onClick={() => getIdToken()}>
        Test ID Token
      </Button>
      <pre>
        <code>{JSON.stringify(user, null, 1)}</code>
      </pre>
    </div>
  );
};
