/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import {
  DynamicRangeSlider,
  MultiList,
  ReactiveBase,
  DateRange,
  ReactiveComponent,
  SelectedFilters
} from '@appbaseio/reactivesearch';
import { ReferenceLine } from 'recharts';
import dateformat from 'dateformat';
import { get, map } from 'lodash';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import LineChartComponent from '../components/Charts/LineChartComponentPSI';
import { useReactiveProps } from '../ReactiveProps';
import theme from './ReactiveBaseTheme';
import ErrorBoundary from '@appbaseio/reactivesearch/lib/components/basic/ErrorBoundary';
import ReactApexChart from 'react-apexcharts';

let minStartTime = 1672808400000 + 3600000; //quick conversion of UTC time to Locale time

const interactiveIds = {
  and: [
    'refine_start_date',
    'start_time_datefilter',
    'refine_stringer',
    'refine_steel_grade',
    'refine_order_id',
    'refine_run_id'
  ],
};

const getLineData = (data) => {
  /*Data Format--->
    schedulePosition:n,
    data= [{
      x:lineName,
      y:[startDate,endDate]
    }]*/
  const generateRandomColor = () => {
    const hue = Math.floor(Math.random() * 360); // Random hue value between 0 and 360
    const saturation = Math.floor(Math.random() * 21) + 40; // Random saturation value between 40 and 60
    const brightness = Math.floor(Math.random() * 21) + 40; // Random brightness value between 40 and 60

    const color = `hsl(${hue}, ${saturation}%, ${brightness}%)`;
    return color;
  };

  var startTime = data.key + 3600000; //Easy conversion of UTC time to Locale time
  minStartTime = Math.min(minStartTime, startTime);
  const lineData = data.prodLines.buckets.map((prodLine) => {
    var line = prodLine.key;
    var scheduleData = prodLine.schedulePositions.buckets.map((schedulePosition) => {
      var position = schedulePosition.key;
      var duration = schedulePosition.duration.value;
      var endTime = startTime + duration;
      const bucket = {
        name: position,
        data: [{
          x: line,
          y: [new Date(startTime).getTime(), new Date(endTime).getTime()],
          fillColor: generateRandomColor()
        }]
      };
      return bucket;
    });
    return scheduleData;
  });
  return lineData;
};

const GanttChart = () => {
  const chartOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: 'datetime',
      min: minStartTime,
      labels: {
        datetimeFormatter: {
          year: 'MMM yyyy',
          month: 'MMM yyyy',
          day: 'dd.MMM HH:mm',
          hour: 'dd.MMM HH:mm',
        },
      },
    },
    yaxis: {
      grid: {
        showLines: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        rangeBarGroupRows: true,
        barHeight: '60%'
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      x: {
        format: 'dd MM yy HH:mm',
      }
    }
  };

  return (
    <ReactiveComponent
      componentId="chart_gantt_schedule"
      defaultQuery={() => ({
        aggs: {
          data: {
            terms: {
              field: 'timestamp',
              size: 100000
            },
            aggs: {
              prodLines: {
                terms: {
                  field: 'prodLineName.keyword'
                }, aggs: {
                  schedulePositions: {
                    terms: {
                      field: 'schedulePosition',
                      size: 1000
                    },
                    aggs: {
                      duration: {
                        avg: {
                          field: 'duration'
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        size: 0,
      })
      }
      URLParams
      showFilter
      react={interactiveIds}
      render={({ aggregations, ...rest }) => {
        const dataSeries = [];
        const data = map(
          get(aggregations, 'data.buckets'),
          (b) => {
            return getLineData(b);
          }
        );
        data.map((items) => {
          items.map((item) => {
            //dataSeries.push(i);
            item.map((i) => {
              dataSeries.push(i);
            });
          });
        });
        return (
          <ResponsiveContainer width="99%" aspect={3}>
            <ReactApexChart options={chartOptions} series={dataSeries} type="rangeBar" height={350} />
          </ResponsiveContainer>
        );
      }}
    />
  );
};

const StringerBarChart = () => (
  <ReactiveComponent
    componentId="chart_bar_stringer"
    defaultQuery={() => ({
      query: {
        match: {
          'dummyCoil': {
            query: 'TRUE'
          }
        },
      },
      aggs: {
        data: {
          date_histogram: {
            field: 'timestamp',
            calendar_interval: '1m',
            format: 'dd-MM-yyyy hh:mm',
          },
        },
      },
      size: 0,
    })}
    URLParams
    showFilter
    react={interactiveIds}
    render={({ aggregations, ...rest }) => {
      const data = map(
        get(aggregations, 'data.buckets'),
        (b) => {
          return {
            key: dateformat(b.key, 'dd.mm.yyyy HH:MM'),
            value: b.doc_count
          };
        }
      );
      return (
        <ResponsiveContainer width="100%" aspect={3}>
          <BarChart
            data={data}
            isAnimationActive={false}
          >
            <XAxis dataKey="key" />
            <YAxis dataKey="value" />
            <Bar
              dataKey="value"
              name="Stringer"
              fill={'red'}
              isAnimationActive={false}
            />
            <Tooltip isAnimationActive={false} />
          </BarChart>
        </ResponsiveContainer>
      );
    }}
  ></ReactiveComponent>
);

const LineChart = ({ componentId, aggregate_field }) => (
  <ReactiveComponent
    componentId={componentId}
    defaultQuery={() => ({
      query: {
        match_all: {},
      },
      aggs: {
        date_bucket: {
          date_histogram: {
            field: 'timestamp',
            calendar_interval: '1m',
            format: 'dd-MM-yyyy',
          },
          aggs: {
            prodLineName: {
              terms: {
                field: 'prodLineName.keyword',
              },
              aggs: {
                aggregate_value: {
                  avg: {
                    field: aggregate_field
                  }
                }
              }
            },

          },
        },
      },
      size: 0,
    })}
    URLParams
    showFilter
    react={interactiveIds}
  >
    {({ aggregations, isLoading }) => {
      if (isLoading || !aggregations) return null;
      let minValue = Infinity;
      const dataKeys = new Set();
      const data = aggregations['date_bucket'].buckets.map((b) => {
        const bucket = { key: dateformat(b.key, 'dd.mmm HH:MM') };
        const lines = b.prodLineName.buckets;
        lines.forEach((a) => {
          if (aggregate_field == 'duration') {
            minValue = Math.min(minValue, a.aggregate_value.value / 60000.0);
            bucket[a.key] = (a.aggregate_value.value / 60000.0).toFixed(1);
          } else {
            minValue = Math.min(minValue, a.aggregate_value.value);
            bucket[a.key] = a.aggregate_value.value.toFixed(2);
          }
          dataKeys.add(a.key);
        });
        return bucket;
      });
      
      return (
        <LineChartComponent
          data={data}
          dataKeys={Array.from(dataKeys).sort((a, b) => a > b)}
          aspect={3}
          yaxis={minValue}
        >
          <ReferenceLine x={580} stroke="red" strokeDasharray="3 3" />
        </LineChartComponent>
      );
    }}
  </ReactiveComponent>
);

export default function PSISchedule(props) {
  const reactiveProps = useReactiveProps('hymas_psi4');
  if (!reactiveProps) return <div />;

  return (
    <ReactiveBase {...reactiveProps} theme={theme}>
      <div className="container-fluid my-2">
        <div className="row g-2">
          <div className="col-3">
            <ErrorBoundary>
              <div className="p-1 bg-white">
                <DynamicRangeSlider
                  componentId="refine_start_date"
                  dataField="timestamp"
                  title="Start Date"
                  showHistogram={true}
                  loader={<Spinner animation="border" size="sm" />}
                  rangeLabels={(min, max) => {
                    return {
                      start: dateformat(min, 'dd.mm.yyyy'),
                      end: dateformat(max, 'dd.mm.yyyy'),
                    };
                  }}
                />
                <hr />
                <DateRange
                  componentId="start_time_datefilter"
                  URLParams
                  showFilter
                  dataField="timestamp"
                />
                <hr />
                <MultiList
                  componentId="refine_stringer"
                  dataField="dummyCoil.keyword"
                  title="Stringer"
                  showCount
                  showFilter
                  showSearch={false}
                  loader={<Spinner animation="border" size="sm" />}
                  URLParams
                  innerClass={{
                    title: 'fw-bold',
                  }}
                  className="mt-1"
                />
                <MultiList
                  componentId="refine_run_id"
                  dataField="RunID.keyword"
                  title="Run ID"
                  showCount
                  showFilter
                  showSearch={false}
                  loader={<Spinner animation="border" size="sm" />}
                  URLParams
                  innerClass={{
                    title: 'fw-bold',
                  }}
                  className="mt-1"
                />
                <MultiList
                  componentId="refine_steel_grade"
                  dataField="steelGrade.keyword"
                  title="Steel Grade"
                  showCount
                  showFilter
                  showSearch={false}
                  loader={<Spinner animation="border" size="sm" />}
                  URLParams
                  innerClass={{
                    title: 'fw-bold',
                  }}
                  className="mt-1"
                />
                <MultiList
                  componentId="refine_order_id"
                  dataField="prodOrderId.keyword"
                  title="Order ID"
                  showCount
                  showFilter
                  showSearch={false}
                  loader={<Spinner animation="border" size="sm" />}
                  URLParams
                  innerClass={{
                    title: 'fw-bold',
                  }}
                  className="mt-1"
                />
                <hr />
              </div>
            </ErrorBoundary>
          </div>

          <div className="col-9">
            <div className="p-1 bg-white">

              <SelectedFilters className="mb-2" />
              {/*
              <div className="p-5 bg-gray" height="200px">
                <h5>Stringers</h5>
                <StringerBarChart />
              </div>*/}

              <ErrorBoundary>
                <div className='row flex-grow' >
                  <h5 style={{ textAlign: 'center', width: '100%' }}>Duration</h5>
                  <GanttChart />
                </div>
              </ErrorBoundary>
              <ErrorBoundary>
                <div className="row flex-grow" >
                  <h5 style={{ textAlign: 'center', width: '100%' }}>Temperature</h5>
                  <LineChart
                    componentId="chart_line_temperature"
                    aggregate_field="selectedTemperature"
                  />
                </div>
                <div className="row">
                  <h5 style={{ textAlign: 'center', width: '100%' }}>Width</h5>
                  <LineChart
                    componentId="chart_line_width"
                    aggregate_field="CGOutputWidthTarget"
                  />
                </div>
                <div className="row">
                  <h5 style={{ textAlign: 'center', width: '100%' }}>Thickness</h5>
                  <LineChart
                    componentId="chart_line_thickness"
                    aggregate_field="CGInputThicknessTarget"
                  />
                </div>
                <div className="row">
                  <h5 style={{ textAlign: 'center', width: '100%' }}>Speed</h5>
                  <LineChart
                    componentId="chart_line_speed"
                    aggregate_field="selectedSpeed"
                  />
                </div>
              </ErrorBoundary>
            </div>
          </div>
        </div>
      </div>
    </ReactiveBase>
  );
}
