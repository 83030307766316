/* eslint-disable no-unused-vars */
import React from 'react';
import { Card } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';

const cardStyle = { width: '18rem' };

export default class Home extends React.Component {
  render() {
    return <Navigate to={'/psischeduling'} />; /* (
      <div className="d-flex justify-content-start">
        <div className="p-2">
          <Card style={cardStyle}>
            <Card.Header className="fw-bold">Zugfestigkeit</Card.Header>
            <Card.Body>Beschreibung...</Card.Body>
          </Card>
        </div>
        <div className="p-2">
          <Card style={cardStyle}>
            <Card.Header className="fw-bold">Produktionsplanung</Card.Header>
            <Card.Body>Beschreibung...</Card.Body>
          </Card>
        </div>
      </div>
    ); */
  }
}
