import { scaleOrdinal, scaleSequential } from 'd3-scale';
import {
  schemeCategory10,
  schemeSet1,
  schemeSet2,
  schemeSet3,
  interpolateRainbow,
} from 'd3-scale-chromatic';

export const range10 = scaleOrdinal(schemeCategory10).range();
export const range9 = scaleOrdinal(schemeSet1).range();
export const range8 = scaleOrdinal(schemeSet2).range();
export const range12 = scaleOrdinal(schemeSet3).range();
export const signalColors = {
  r: '#E73F3F',
  o: '#FF8243',
  y: '#FFFB5A',
  g: '#44AF56',
};
export const rainbow = scaleSequential(interpolateRainbow);

export const layerChartColors = [
  '#51AAE6',
  '#A8DFDF',
  '#33a02c',
  '#65D25E',
  '#FF4C4E',
  '#FFCCCB',
  '#FFB132',
  '#FFF1A1',
  '#9C6FCC',
  '#FCE4FF',
  '#E38B5A',
  '#FFFFCB',
];
