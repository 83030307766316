/* eslint-disable react/prop-types */
import React from 'react';
export default function Source({ sourceObject }) {
  const orderedKeys = (obj) =>
    Object.keys(obj)
      .sort()
      .reduce((acc, key) => {
        acc[key] = obj[key];
        return acc;
      }, {});
  return (
    <details>
      <summary>Source</summary>
      <pre>
        <code>
          {typeof sourceObject === 'string'
            ? sourceObject
            : JSON.stringify(orderedKeys(sourceObject), null, 4)}
        </code>
      </pre>
    </details>
  );
}
