/* eslint-disable react/prop-types */
import React from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Surface,
  Symbols,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { includes, without } from 'lodash';
//import { range10 as colors } from './colors';

const generateColors = (count) => {
  const goldenRatio = 0.618033988749895; 
  const saturation = 50;
  const lightness = 60;
  const colors = [];

  for (let i = 0; i < count; i++) {
    const hue = Math.round((i * goldenRatio * 360) % 360);
    const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    colors.push(color);
  }

  return colors;
};

const colors = generateColors(100);

function LineChartComponent({ data, dataKeys, yaxis, aspect = 2, children }) {
  const [disabled, setDisabled] = React.useState([]);

  const renderCustomizedLegend = React.useCallback(
    ({ payload }) => {
      const handleClick = (dataKey) => {
        if (includes(disabled, dataKey)) {
          setDisabled(disabled.filter((obj) => obj !== dataKey));
        } else {
          setDisabled(disabled.concat(dataKey));
        }
      };

      return (
        <div className="customized-legend text-center">
          {payload.map((entry) => {
            const { dataKey, color } = entry;
            const active = includes(disabled, dataKey);
            const style = {
              marginRight: 10,
              color: active ? '#AAA' : '#000',
            };

            return (
              <span
                key={dataKey}
                className="legend-item"
                onClick={() => handleClick(dataKey)}
                style={style}
              >
                <Surface width={10} height={10} viewBox="0 0 10 10">
                  <Symbols
                    cx={5}
                    cy={5}
                    type="diamond"
                    size={50}
                    fill={color}
                  />
                  {active && (
                    <Symbols
                      cx={5}
                      cy={5}
                      type="diamond"
                      size={25}
                      fill={'#FFF'}
                    />
                  )}
                </Surface>
                <span className="ms-2">{dataKey}</span>
              </span>
            );
          })}
        </div>
      );
    },
    [disabled]
  );

  const xLabel =
    dataKeys || without(data?.length ? Object.keys(data[0]) : [], 'key');

  return (
    <ResponsiveContainer width="100%" aspect={aspect}>
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 20,
          left: 0,
          bottom: 25,
        }}
      >
        <CartesianGrid />
        <XAxis dataKey="key" interval={'preserveStartEnd'} angle={-10} />
        {typeof yaxis === 'number' ? (
          <YAxis domain={[yaxis, 'auto']} />
        ) : (
          <YAxis {...(yaxis || {})} />
        )}

        <Legend
          payload={xLabel.map((l, i) => ({
            dataKey: l,
            color: colors[i % colors.length],
          }))}
          content={renderCustomizedLegend}
        />
        <Tooltip isAnimationActive={false} />
        {xLabel.map((l, i) => {
          return (
            !includes(disabled, l) && (
              <Line
                key={l}
                dataKey={l}
                stroke={colors[i % colors.length]}
                dot={false}
                isAnimationActive={false}
                strokeWidth={2}
                connectNulls={true}
                type="step"
              />
            )
          );
        })}
        {/*<Brush dataKey="name" height={30} fill="#e6e6e6" stroke="#555555"/>*/}
        {children}
      </LineChart>
    </ResponsiveContainer>
  );
}

export default React.memo(LineChartComponent);

export function elasticToLineChartData(aggs) {
  if (!aggs) return [];
  const map = new Map();
  Object.keys(aggs).forEach((k) => {
    try {
      convertLineChartData(aggs[k].buckets, k, map);
    } catch (error) {
      console.error(error);
    }
  });
  return map.values();
}

function convertLineChartData(buckets, field, map) {
  buckets.forEach((b) => {
    if (map.has(b.key)) {
      map.set(b.key, {
        ...map.get(b.key),
        ...{ key: b.key, [field]: b.doc_count },
      });
    } else {
      map.set(b.key, {
        key: b.key,
        [field]: b.doc_count,
      });
    }
  });
}
