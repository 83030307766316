/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import uniqueId from 'lodash/uniqueId';
import { Spinner } from 'react-bootstrap';

function PieChartComponent({
  loading,
  aggs,
  width,
  height,
  onClick = () => {},
  colorMap = {},
}) {
  const data = map(get(aggs, 'data.buckets'), (b) => ({
    name: b.key_as_string || b.key,
    value: b.doc_count,
  }));
  if (loading)
    return (
      <div>
        <Spinner animation="border" size="sm" />
      </div>
    );
  if (isEmpty(data)) return <div />;
  return (
    <div style={{ width: width || '100%', height: height || '300px' }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            isAnimationActive={false}
            dataKey="value"
            data={data}
            innerRadius={10}
            onClick={({ name }) => !loading && onClick(name)}
          >
            {data.map((entry, _index) => (
              <Cell
                key={uniqueId('cell')}
                fill={(colorMap && colorMap[entry['name']]) || 'lightgray'}
              />
            ))}
          </Pie>
          <Legend
            layout="vertical"
            align="right"
            verticalAlign="middle"
            formatter={(value, _entry, _index) => (
              <span className="text-body">{value}</span>
            )}
          />
          <Tooltip isAnimationActive={false} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default React.memo(PieChartComponent);
