import { useAuth0 } from '@auth0/auth0-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Container, Nav, Navbar, Row } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { LoginButton } from './components/Buttons/LoginButton';
import { LogoutButton } from './components/Buttons/LogoutButton';
import { PageLoader } from './components/PageLoader';
import PrivateRoute from './components/PrivateRoute';
import Analyics from './pages/Analytics';
import Charts from './pages/Charts';
import Home from './pages/Home';
import Dailabs from './pages/Dailabs';
// import Overview from './pages/Overview';
import { Profile } from './pages/Profile';
import { ProtectedPage } from './pages/ProtectedPage';
import TensileStrength from './pages/TensileStrength';
import PSISchedule from './pages/PSISchedule';

function App() {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) return <PageLoader />;

  return (
    <>
      <Container fluid className="border-bottom bg-white">
        <Row className="g-2">
          <Navbar className="col py-0">
            <Navbar.Brand href="https://www.hymas-projekt.de/" target="_blank">
              <img
                src="hymas_logo.png"
                className="d-inline-block align-top"
                alt="HyMAS Logo"
                height="50"
              />
            </Navbar.Brand>
            <Nav className="me-auto">
              {/* <Nav.Link href="/">Home</Nav.Link> */}
              {isAuthenticated && (
                <>
                  <Nav.Link href="/psischeduling">PSI Scheduling</Nav.Link>
                  <Nav.Link href="/dailabs">DAI Lab</Nav.Link>
                  <Nav.Link href="/meshymas">POM Simulation</Nav.Link>
                  <Nav.Link href="/tensilestrength">Tensile Strength</Nav.Link>
                  <Nav.Link href="/productionplanning">
                    Production Planning
                  </Nav.Link>
                </>
              )}
              {isAuthenticated && process.env.NODE_ENV === 'development' && (
                <>
                  <Nav.Link href="/protected">Protected</Nav.Link>
                  <Nav.Link href="/profile">Profile</Nav.Link>
                </>
              )}
            </Nav>
            <Nav>
              {isAuthenticated && <LogoutButton />}
              {!isAuthenticated && <LoginButton />}
            </Nav>
            <Nav>
              <img
                src="EU_Emblem.png"
                className="d-inline-block align-top"
                alt="EU Logo"
                height="50"
                title="Dieses Projekt wird kofinanziert durch den Europäischen Fonds für regionale Entwicklung (EFRE)."
              />
            </Nav>
          </Navbar>
        </Row>
      </Container>
      <Routes>
        <Route index element={<Home />} />
        {/* <Route
          path="/tensilestrength"
          element={<PrivateRoute element={<Overview />} />}
        /> */}
        <Route
          path="/psischeduling"
          element={<PrivateRoute element={<PSISchedule />} />}
        />
        <Route
          path="/tensilestrength"
          element={<PrivateRoute element={<TensileStrength />} />}
        />
        <Route
          path="/productionplanning"
          element={<PrivateRoute element={<Analyics />} />}
        />
        <Route
          path="/meshymas"
          element={<PrivateRoute element={<Charts />} />}
        />
        <Route
          path="/dailabs"
          element={<PrivateRoute element={<Dailabs />} />}
        />
        <Route
          path="/protected"
          element={<PrivateRoute element={<ProtectedPage />} />}
        />
        <Route
          path="/profile"
          element={<PrivateRoute element={<Profile />} />}
        />
      </Routes>
    </>
  );
}

export default App;
