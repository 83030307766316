import {
  DynamicRangeSlider,
  MultiList,
  ReactiveList,
  SelectedFilters,
} from '@appbaseio/reactivesearch';
import dateformat from 'dateformat';
import ReactECharts from 'echarts-for-react';
import React from 'react';
import { Col, Container, Row, Spinner, Tab } from 'react-bootstrap';
import ControlledTabs from '../components/ControlledTabs';

function min_max(minmax, value) {
  minmax[0] = Math.min(minmax[0], value);
  minmax[1] = Math.max(minmax[1], value);
}

const DataAnalysisPCC = () => {
  return (
    <Row>
      <Col className="col-2 p-2">
        <Container>
          <Row>
            <Col>
              <SelectedFilters />
              <DynamicRangeSlider
                componentId="schedule_refine_event_time"
                dataField="event_time"
                title="Event Date"
                showHistogram={true}
                queryFormat="date"
                calendarInterval="day"
                innerClass={{
                  title: 'fw-bold',
                }}
                loader={<Spinner animation="border" size="sm" />}
                rangeLabels={(min, max) => {
                  return {
                    start: dateformat(min, 'dd.mm.yyyy'),
                    end: dateformat(max, 'dd.mm.yyyy'),
                  };
                }}
                className="mt-1"
                URLParams
              />
              <hr />
              <MultiList
                componentId="refine_hymas_type"
                dataField="hymas_action_desc.keyword"
                title="HyMAS Action"
                showCount
                showFilter
                showSearch={false}
                loader={<Spinner animation="border" size="sm" />}
                URLParams
                innerClass={{
                  title: 'fw-bold',
                }}
                className="mt-1"
              />
              <MultiList
                componentId="refine_mes_event"
                dataField="mes_event_desc.keyword"
                title="MES Event"
                showCount
                showFilter
                showSearch={false}
                loader={<Spinner animation="border" size="sm" />}
                URLParams
                innerClass={{
                  title: 'fw-bold',
                }}
                className="mt-1"
              />
            </Col>
          </Row>
        </Container>
      </Col>
      <Col className="col-8">
        <ControlledTabs param="pccdiagram" defaultTab="pcc" transition={false}>
          <Tab eventKey="pcc" title="PCC">
            <ReactiveList
              componentId="chart_parallel"
              react={{
                and: [
                  'schedule_refine_event_time',
                  'refine_hymas_type',
                  'refine_mes_event',
                ],
              }}
              style={{ height: '600px' }}
              size={10000}
            >
              {({ loading, data }) => {
                if (loading) return null;
                console.log(+new Date(), data);
                const noOfCoils = [0, 0];
                const deltaStringers = [0, 0];
                const deltaDelay = [0, 0];
                const series = data.map((d) => {
                  const entry = [
                    d['mes_event_desc'],
                    d['hymas_action_desc'],
                    d.shift,
                    d['number_of_coils_in_schedule'],
                    d['delta_stringers'],
                    d['delta_delay'],
                  ];

                  min_max(noOfCoils, d['number_of_coils_in_schedule']);
                  min_max(deltaStringers, d['delta_stringers']);
                  min_max(deltaDelay, d['delta_delay']);

                  return {
                    name: entry[0],
                    type: 'parallel',
                    data: [entry],
                  };
                });

                const options = {
                  animation: false,
                  legend: {
                    type: 'scroll',
                    top: 20,
                    itemGap: 20,
                  },
                  tooltip: {
                    padding: 10,
                    borderColor: '#777',
                    borderWidth: 1,
                  },
                  parallel: {
                    left: 250,
                    parallelAxisDefault: {
                      nameLocation: 'start',
                      axisLine: { lineStyle: { color: '#000' } },
                      axisLabel: {
                        align: 'right',
                        margin: '-10',
                        width: 200,
                        overflow: 'break',
                      },
                      axisTick: { alignWithLabel: true },
                      minorTick: { show: true },
                    },
                  },
                  parallelAxis: [
                    {
                      dim: 0,
                      name: 'MES Event',
                      type: 'category',
                    },
                    {
                      dim: 1,
                      name: 'HyMAS Action',
                      type: 'category',
                    },
                    {
                      dim: 2,
                      name: 'Shift',
                      type: 'category',
                    },
                    {
                      dim: 3,
                      name: 'No of coils in schedule',
                      min: noOfCoils[0],
                      max: noOfCoils[1],
                    },
                    {
                      dim: 4,
                      type: 'value',
                      name: 'Delta Stringers',
                      min: deltaStringers[0],
                      max: deltaStringers[1],
                    },
                    {
                      dim: 5,
                      type: 'value',
                      name: 'Delta Delay',
                      min: deltaDelay[0],
                      max: deltaDelay[1],
                    },
                  ],
                  series,
                };

                return (
                  <>
                    <ReactECharts
                      option={options}
                      style={{ height: '100%', width: '100%' }}
                      opts={{ renderer: 'svg' }}
                    />
                  </>
                );
              }}
            </ReactiveList>
          </Tab>
        </ControlledTabs>
      </Col>
    </Row>
  );
};

export default DataAnalysisPCC;
