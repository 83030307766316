/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import {
  DynamicRangeSlider,
  ErrorBoundary,
  MultiList,
  ReactiveBase,
  ReactiveComponent,
  ReactiveList,
  ResultList,
  SelectedFilters,
} from '@appbaseio/reactivesearch';
import React from 'react';
import { Spinner, Tab, Tabs } from 'react-bootstrap';
import { ReferenceLine } from 'recharts';
import { useReactiveProps } from '../ReactiveProps';
import LineChartComponent, {
  elasticToLineChartData,
} from '../components/Charts/LineChartComponent';
import PieChartComponent from '../components/Charts/PieChartComponent';
import { signalColors } from '../components/Charts/colors';
import Source from '../components/Develop/Source';
import countFormatter from '../utils/CountFormatter';
import theme from './ReactiveBaseTheme';

const interactiveIds = {
  and: [
    'refine_path',
    'refine_anlage',
    'refine_yabs',
    'refine_ypred',
    'refine_ytrue',
    'refine_yopt',
    'refine_qflag',
    'refine_ypred_qflag',
    'refine_ytrue_qflag',
    'refine_yoptimiert_qflag',
    'refine_nww3_dg',
    'chart_qflag',
    'chart_ypred_qflag',
    'chart_ytrue_qflag',
    'chart_nww3dg',
    'chart_ypred_ytrue',
    'chart_yoptimiert_qflag',
    'chart_nwgg1',
    'chart_y_opt',
    'chart_y_true',
    'chart_y_pred',
  ],
};

/* simple render func for multilist */
const multiListItem = (label, count, isSelected) => (
  <span className={isSelected ? 'fw-bold' : undefined}>
    <span>{label} </span>
    <span>{countFormatter(count)}</span>
  </span>
);

const transformMLSignals = (data) => {
  const transformed = [];
  data.forEach((d) => {
    switch (d.key) {
    case 'r':
      transformed[3] = d;
      break;
    case 'o':
      transformed[2] = d;
      break;
    case 'y':
      transformed[1] = d;
      break;
    case 'g':
      transformed[0] = d;
      break;
    default:
    }
  });
  return transformed;
};

const renderMLSignals = (label, count) => {
  switch (label) {
  case 'g':
    label = 'Green';
    break;
  case 'y':
    label = 'Yellow';
    break;
  case 'o':
    label = 'Orange';
    break;
  case 'r':
    label = 'Red';
    break;
  default:
  }
  return (
    <span>
      <span>{label}</span>
      <span>{count}</span>
    </span>
  );
};

const SearchResultList = (
  <ErrorBoundary>
    <ReactiveList
      dataField="Y_ABS"
      componentId="results"
      pagination={true}
      react={interactiveIds}
      sortOptions={[
        {
          label: 'Calculated Tensile Strength desc',
          dataField: 'Y_Pred',
          sortBy: 'desc',
        },
        {
          label: 'Calculated Tensile Strength asc',
          dataField: 'Y_Pred',
          sortBy: 'asc',
        },
        {
          label: 'Optimated Tensile Strength desc',
          dataField: 'Y_Optimiert',
          sortBy: 'desc',
        },
        {
          label: 'Optimated Tensile Strength asc',
          dataField: 'Y_Optimiert',
          sortBy: 'asc',
        },
        {
          label: 'Actual Tensile Strength desc',
          dataField: 'Y_True',
          sortBy: 'desc',
        },
        {
          label: 'Actual Tensile Strength asc',
          dataField: 'Y_True',
          sortBy: 'asc',
        },
        {
          label: 'Absolute Difference desc',
          dataField: 'Y_ABS',
          sortBy: 'desc',
        },
        { label: 'Absolute Difference asc', dataField: 'Y_ABS', sortBy: 'asc' },
      ]}
      defaultSortOption={'Absolute Difference desc'}
      render={({ data }) => (
        <div>
          {data.map((item) => (
            <ResultList key={item._id}>
              <ResultList.Content>
                <div className="d-flex flex-wrap">
                  <div className="me-2">
                    <b>Source:</b>
                    &nbsp;
                    <span>{item.path}</span>
                  </div>
                  <div className="me-2">
                    <b>Actual Tensile Strength:</b>
                    &nbsp;
                    <span>{item.Y_True}</span>
                  </div>
                  <div className="me-2">
                    <b>Calculated Tensile Strength:</b>
                    &nbsp;
                    <span>{item.Y_Pred}</span>
                  </div>
                  <div className="me-2">
                    <b>Absolute Difference:</b>
                    &nbsp;
                    <span>{item.Y_ABS}</span>
                  </div>
                  <div className="me-2">
                    <b>Optimated Tensile Strength Difference:</b>
                    &nbsp;
                    <span>{item.Y_Opt_Diff}</span>
                  </div>
                  <div className="me-2">
                    <b>Optimated Tensile Strength:</b>
                    &nbsp;
                    <span>{item.Y_Optimiert}</span>
                  </div>
                </div>
                <Source sourceObject={item} />
              </ResultList.Content>
            </ResultList>
          ))}
        </div>
      )}
    />
  </ErrorBoundary>
);

const YABSPieChart = () => (
  <div className="border shadow-sm p-1 mb-2" style={{ minWidth: '300px' }}>
    <b>
      <u>Absolute Difference</u>
    </b>
    <ReactiveComponent
      componentId="chart_qflag"
      defaultQuery={() => ({
        aggs: {
          data: {
            terms: {
              field: 'Y_QFlag.keyword',
              size: 10,
            },
          },
        },
        size: 0,
      })}
      render={({ aggregations, setQuery, ...rest }) => (
        <PieChartComponent
          aggs={aggregations}
          onClick={(value) => {
            setQuery({
              query: { term: { 'Y_QFlag.keyword': value } },
              value,
            });
          }}
          colorMap={signalColors}
          width={'300px'}
          height={'200px'}
          {...rest}
        />
      )}
      URLParams
      showFilter
      react={interactiveIds}
    />
    <div className="vstack gap-1">
      <div className="bg-light border">{'(g)reen: 0...4'}</div>
      <div className="bg-light border">{'(y)ellow: >4...8'}</div>
      <div className="bg-light border">{'(o)range: >8...16'}</div>
      <div className="bg-light border">{'(r)ed: >16'}</div>
    </div>
  </div>
);

const YPredPieChart = () => (
  <div className="border shadow-sm p-1 mb-2" style={{ minWidth: '300px' }}>
    <b>
      <u>Predicted Tensile Strength</u>
    </b>
    <ReactiveComponent
      componentId="chart_ypred_qflag"
      defaultQuery={() => ({
        aggs: {
          data: {
            terms: {
              field: 'Y_Pred_QFlag.keyword',
              size: 10,
            },
          },
        },
        size: 0,
      })}
      render={({ aggregations, setQuery, ...rest }) => (
        <PieChartComponent
          aggs={aggregations}
          onClick={(value) => {
            setQuery({
              query: { term: { 'Y_Pred_QFlag.keyword': value } },
              value,
            });
          }}
          colorMap={signalColors}
          width={'300px'}
          height={'200px'}
          {...rest}
        />
      )}
      URLParams
      showFilter
      react={interactiveIds}
    />
    <div className="vstack gap-1">
      <div className="bg-light border">{'(g)reen: +/- 15'}</div>
      <div className="bg-light border">{'(y)ellow: +/- 30'}</div>
      <div className="bg-light border">{'(o)range: +/- 50'}</div>
      <div className="bg-light border">{'(r)ed: +/- 50+'}</div>
    </div>
  </div>
);

const YTruePieChart = () => (
  <div className="border shadow-sm p-1 mb-2" style={{ minWidth: '300px' }}>
    <b>
      <u>True Tensile Strength</u>
    </b>
    <ReactiveComponent
      componentId="chart_ytrue_qflag"
      defaultQuery={() => ({
        aggs: {
          data: {
            terms: {
              field: 'Y_True_QFlag.keyword',
              size: 10,
            },
          },
        },
        size: 0,
      })}
      render={({ aggregations, setQuery, ...rest }) => (
        <PieChartComponent
          aggs={aggregations}
          onClick={(value) => {
            setQuery({
              query: { term: { 'Y_True_QFlag.keyword': value } },
              value,
            });
          }}
          colorMap={signalColors}
          width={'300px'}
          height={'200px'}
          {...rest}
        />
      )}
      URLParams
      showFilter
      react={interactiveIds}
    />
    <div className="vstack gap-1">
      <div className="bg-light border">{'(g)reen: +/- 15'}</div>
      <div className="bg-light border">{'(y)ellow: +/- 30'}</div>
      <div className="bg-light border">{'(o)range: +/- 50'}</div>
      <div className="bg-light border">{'(r)ed: +/- 50+'}</div>
    </div>
  </div>
);

const YOptimiertPieChart = () => (
  <div className="border shadow-sm p-1 mb-2" style={{ minWidth: '300px' }}>
    <b>
      <u>Optimated Tensile Strength</u>
    </b>
    <ReactiveComponent
      componentId="chart_yoptimiert_qflag"
      defaultQuery={() => ({
        aggs: {
          data: {
            terms: {
              field: 'Y_Optimiert_QFlag.keyword',
              size: 10,
            },
          },
        },
        size: 0,
      })}
      render={({ aggregations, setQuery, ...rest }) => (
        <PieChartComponent
          aggs={aggregations}
          onClick={(value) => {
            setQuery({
              query: { term: { 'Y_Optimiert_QFlag.keyword': value } },
              value,
            });
          }}
          colorMap={signalColors}
          width={'300px'}
          height={'200px'}
          {...rest}
        />
      )}
      URLParams
      showFilter
      react={interactiveIds}
    />
    <div className="vstack gap-1">
      <div className="bg-light border">{'(g)reen: +/- 15'}</div>
      <div className="bg-light border">{'(y)ellow: +/- 30'}</div>
      <div className="bg-light border">{'(o)range: +/- 50'}</div>
      <div className="bg-light border">{'(r)ed: +/- 50+'}</div>
    </div>
  </div>
);

const YLineChart = React.memo(({ aggregations, loading }) => {
  if (loading)
    return (
      <div>
        <Spinner animation="border" size="sm" />
      </div>
    );

  let pdata = [...elasticToLineChartData(aggregations)].sort(
    (a, b) => a.key - b.key
  );

  return (
    <>
      <LineChartComponent
        data={pdata}
        dataKeys={[
          'Predicted Tensile Strength',
          'Actual Tensile Strength',
          'Optimated Tensile Strength',
        ]}
        aspect={3}
      >
        <ReferenceLine x={580} stroke="red" strokeDasharray="3 3" />
      </LineChartComponent>
    </>
  );
});

const LineChart = ({ componentId, dataField }) => (
  <ReactiveComponent
    componentId={componentId}
    defaultQuery={() => ({
      query: {
        match_all: {},
      },
      aggs: {
        [dataField]: {
          histogram: {
            field: dataField,
            interval: 1,
            min_doc_count: 1,
          },
          aggs: {
            anlage: {
              terms: {
                field: 'X.VA.Anlage.keyword',
              },
            },
          },
        },
      },
      size: 0,
    })}
    URLParams
    showFilter
    react={interactiveIds}
  >
    {({ aggregations, isLoading }) => {
      if (isLoading || !aggregations) return null;

      const dataKeys = new Set();
      const data = aggregations[dataField].buckets.map((b) => {
        const bucket = { key: b.key };
        const anlagen = b.anlage.buckets;
        anlagen.forEach((a) => {
          bucket[a.key] = a.doc_count;
          dataKeys.add(a.key);
        });
        return bucket;
      });

      return (
        <LineChartComponent
          data={data}
          dataKeys={Array.from(dataKeys).sort((a, b) => a > b)}
          aspect={3}
        >
          <ReferenceLine x={580} stroke="red" strokeDasharray="3 3" />
        </LineChartComponent>
      );
    }}
  </ReactiveComponent>
);

export default function TensileStrength() {
  const reactiveProps = useReactiveProps('hymas_src_predictions');
  if (!reactiveProps) return <div />;
  return (
    <ReactiveBase {...reactiveProps} theme={theme}>
      <div className="container-fluid my-2">
        <div className="row g-2">
          <div className="col-3">
            <div className="p-1 bg-white">
              <MultiList
                componentId="refine_path"
                dataField="path.keyword"
                title="Source"
                aggregationSize={10}
                showFilter
                showSearch={false}
                renderItem={(label, count, isSelected) => {
                  const newLabel = label.split('\\').pop().split('/').pop();
                  return multiListItem(newLabel, count, isSelected);
                }}
              />
              <MultiList
                componentId="refine_anlage"
                dataField="X.VA.Anlage.keyword"
                title="Anlage"
                aggregationSize={10}
                showFilter
                showSearch={false}
                renderItem={multiListItem}
                sortBy="asc"
              />
              <hr />
              <ErrorBoundary>
                <DynamicRangeSlider
                  componentId="refine_yabs"
                  dataField="Y_ABS"
                  title="Absolute Difference"
                  showHistogram={false}
                  rangeLabels={(min, max) => ({
                    start: Math.round(min),
                    end: Math.round(max),
                  })}
                  interval={2}
                  loader={<Spinner animation="border" size="sm" />}
                  tooltipTrigger="hover"
                />
              </ErrorBoundary>
              <hr />
              <ErrorBoundary>
                <DynamicRangeSlider
                  componentId="refine_yopt"
                  dataField="Y_Optimiert"
                  title="Optimated Tensile Strength"
                  showHistogram={false}
                  rangeLabels={(min, max) => ({
                    start: Math.round(min),
                    end: Math.round(max),
                  })}
                  interval={2}
                  loader={<Spinner animation="border" size="sm" />}
                  tooltipTrigger="hover"
                />
              </ErrorBoundary>
              <hr />
              <MultiList
                componentId="refine_qflag"
                dataField="Y_QFlag.keyword"
                title={'Absolute Difference Signals'}
                aggregationSize={5}
                showFilter
                showSearch={false}
                transformData={transformMLSignals}
                loader={<Spinner animation="border" size="sm" />}
                renderItem={renderMLSignals}
                filterLabel="Absolute Difference Signal"
              />
              <hr />
              <MultiList
                componentId="refine_ypred_qflag"
                dataField="Y_Pred_QFlag.keyword"
                title="Predicted Tensile Strength"
                aggregationSize={5}
                showFilter
                showSearch={false}
                transformData={transformMLSignals}
                loader={<Spinner animation="border" size="sm" />}
                renderItem={renderMLSignals}
              />
              <hr />
              <MultiList
                componentId="refine_ytrue_qflag"
                dataField="Y_True_QFlag.keyword"
                title="True Tensile Strength"
                aggregationSize={5}
                showFilter
                showSearch={false}
                transformData={transformMLSignals}
                loader={<Spinner animation="border" size="sm" />}
                renderItem={renderMLSignals}
              />
              <hr />
              <MultiList
                componentId="refine_yoptimiert_qflag"
                dataField="Y_Optimiert_QFlag.keyword"
                title="Optimated Tensile Strength"
                aggregationSize={5}
                showFilter
                showSearch={false}
                transformData={transformMLSignals}
                loader={<Spinner animation="border" size="sm" />}
                renderItem={renderMLSignals}
              />
            </div>
          </div>
          <div className="col-9">
            <div className="p-1 bg-white">
              <SelectedFilters className="mb-2" />
              <Tabs defaultActiveKey="charts" className="mb-2">
                <Tab eventKey="charts" title="Charts">
                  <div
                    className="border shadow-sm p-1 mb-2"
                    style={{ minWidth: '300px' }}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-2"></div>
                        <div className="col-10">
                          <div className="row">
                            <h5>Predicted</h5>
                            <LineChart
                              componentId="chart_y_pred"
                              dataField="Zugfestig.l.g_pred"
                            />
                          </div>
                          <div className="row">
                            <h5>True</h5>
                            <LineChart
                              componentId="chart_y_true"
                              dataField="Zugfestig.l.g_true"
                            />
                          </div>
                          <div className="row">
                            <h5>Optimized</h5>
                            <LineChart
                              componentId="chart_y_opt"
                              dataField="Y_Optimiert"
                            />
                          </div>
                          {/* <ReactiveComponent
                            componentId="chart_y_pred"
                            defaultQuery={() => ({
                              query: {
                                match_all: {},
                              },
                              aggs: {
                                'Zugfestig.l.g_pred': {
                                  histogram: {
                                    field: 'Zugfestig.l.g_pred',
                                    interval: 1,
                                    min_doc_count: 1,
                                  },
                                  aggs: {
                                    anlage: {
                                      terms: {
                                        field: 'X.VA.Anlage.keyword',
                                      },
                                    },
                                  },
                                },
                              },
                              size: 0,
                            })}
                            URLParams
                            showFilter
                            react={interactiveIds}
                          >
                            {({ aggregations, isLoading }) => {
                              if (isLoading || !aggregations) return null;

                              const dataKeys = new Set();
                              const data = aggregations[
                                'Zugfestig.l.g_pred'
                              ].buckets.map((b) => {
                                const bucket = { key: b.key };
                                const anlagen = b.anlage.buckets;
                                anlagen.forEach((a) => {
                                  bucket[a.key] = a.doc_count;
                                  dataKeys.add(a.key);
                                });
                                return bucket;
                              });

                              return (
                                <LineChartComponent
                                  data={data}
                                  dataKeys={Array.from(dataKeys)}
                                  aspect={3}
                                >
                                  <ReferenceLine
                                    x={580}
                                    stroke="red"
                                    strokeDasharray="3 3"
                                  />
                                </LineChartComponent>
                              );
                            }}
                          </ReactiveComponent> */}
                          <div className="row">
                            <h5>All</h5>
                            <ReactiveComponent
                              componentId="chart_ypred_ytrue"
                              defaultQuery={() => ({
                                aggs: {
                                  'Predicted Tensile Strength': {
                                    histogram: {
                                      field: 'Zugfestig.l.g_pred',
                                      interval: 0.1,
                                      min_doc_count: 1,
                                    },
                                  },
                                  'Actual Tensile Strength': {
                                    histogram: {
                                      field: 'Zugfestig.l.g_true',
                                      interval: 0.1,
                                      min_doc_count: 1,
                                    },
                                  },
                                  'Optimated Tensile Strength': {
                                    histogram: {
                                      field: 'Y_Optimiert',
                                      interval: 0.1,
                                      min_doc_count: 1,
                                    },
                                  },
                                },
                                size: 0,
                              })}
                              URLParams
                              showFilter
                              react={interactiveIds}
                            >
                              {(props) => <YLineChart {...props} />}
                            </ReactiveComponent>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between flex-wrap">
                    <YPredPieChart />
                    <YTruePieChart />
                    <YOptimiertPieChart />
                    <YABSPieChart />
                  </div>
                </Tab>
                <Tab eventKey="searchresult" title="Search Result">
                  <ErrorBoundary>{SearchResultList}</ErrorBoundary>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </ReactiveBase>
  );
}
