import React from 'react';
import { useAccessToken } from './AppAuth0Provider.js';

import config from './config.mjs';

export function useReactiveProps(app) {
  const accessToken = useAccessToken();
  const [reactiveProps, setReactiveProps] = React.useState();

  React.useEffect(() => {
    if (config && accessToken) {
      setReactiveProps({
        app,
        url: config.elasticURL,
        headers: { Authorization: `Bearer ${accessToken}` },
      });
    } else {
      setReactiveProps(undefined);
    }
  }, [app, accessToken]);

  return reactiveProps;
}
