/* eslint-disable react/prop-types */
import { Sunburst } from '@nivo/sunburst';
import { find, flatten } from 'lodash';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import isEqual from 'lodash/isEqual';
import { useTheme } from '@nivo/core';

const CustomTooltip = ({ id, value, color }) => {
  const theme = useTheme();
  const newId = id.split('__').pop();

  return (
    <div style={{ ...theme.tooltip.container }}>
      <span style={{ color }}>&#9632;&nbsp;</span>
      {newId}: {value}
    </div>
  );
};

const MyResponsiveSunburst = ({ data }) => {
  const [state, setState] = React.useState(data);

  // eslint-disable-next-line no-unused-vars
  const CenteredMetric = (context) => {
    return (
      <text
        x={context.centerX}
        y={context.centerY}
        textAnchor="middle"
        onClick={() => state.name !== data.name && setState(data)}
      >
        {state.name}
      </text>
    );
  };

  React.useEffect(() => {
    setState(data);
  }, [data]);

  return (
    <Row>
      <Col>
        <div style={{ width: 350, height: 350 }}>
          <div className="text-end">
            <Button
              variant="secondary"
              size="sm"
              onClick={() => setState(data)}
            >
              Reset Chart
            </Button>
          </div>
          <Sunburst
            width={350}
            height={350}
            data={state}
            margin={{ top: 40, right: 20, bottom: 20, left: 20 }}
            id="name"
            value="value"
            cornerRadius={2}
            borderWidth={1}
            borderColor="white"
            colors={{ scheme: 'nivo' }}
            childColor={{ from: 'color', modifiers: [['brighter', 0.5]] }}
            inheritColorFromParent={true}
            animate={true}
            motionConfig="gentle"
            enableArcLabels
            arcLabelsSkipAngle={12}
            valueFormat={(v) => {
              return v;
            }}
            /* arcLabel="value" */
            layers={['arcs', 'arcLabels', CenteredMetric]}
            transitionMode="pushIn"
            onClick={(clickedData) => {
              const foundObject = find(
                flatten(state.children),
                (d) => d.name === clickedData.id
              );
              if (foundObject && foundObject.children) {
                setState(foundObject);
              }
            }}
            tooltip={CustomTooltip}
          />
        </div>
      </Col>
    </Row>
  );
};

export default React.memo(MyResponsiveSunburst, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
