import {
  DynamicRangeSlider,
  ReactiveComponent,
} from '@appbaseio/reactivesearch';
import React from 'react';
import { Col, ListGroup, Row, Spinner, Table } from 'react-bootstrap';
import Chart from 'react-apexcharts';
import dateformat from 'dateformat';
import { PieChartData, PieOptions } from '../components/Charts/ApexChartsUtl';

const DataAnalysisOverview = () => {
  return (
    <Row>
      <Col xs={2} className="p-2">
        <DynamicRangeSlider
          componentId="overview_refine_event_time"
          dataField="event_time"
          title="Event Date"
          showHistogram={true}
          queryFormat="date"
          calendarInterval="day"
          innerClass={{
            title: 'fw-bold',
          }}
          loader={<Spinner animation="border" size="sm" />}
          rangeLabels={(min, max) => {
            return {
              start: dateformat(min, 'dd.mm.yyyy'),
              end: dateformat(max, 'dd.mm.yyyy'),
            };
          }}
          className="mt-1"
        />
      </Col>
      <Col>
        <Row>
          <Col>
            <ReactiveComponent
              componentId="overview_generic"
              defaultQuery={() => ({
                size: 0,
                aggs: {
                  number_of_coils: {
                    sum: {
                      field: 'number_of_coils_in_schedule',
                    },
                  },
                  number_of_hymas_actions: {
                    value_count: {
                      field: 'hymas_action_no',
                    },
                  },
                  delay_stats: {
                    stats: {
                      field: 'delta_delay',
                    },
                  },
                },
              })}
              render={(args) => {
                if (!args.aggregations) return null;
                const {
                  number_of_coils,
                  number_of_hymas_actions,
                  delay_stats,
                } = args.aggregations;
                return (
                  <div className="p-2">
                    <h2>Overall statistics</h2>
                    <Table bordered size="sm">
                      <tbody>
                        <tr>
                          <th>Number of HyMAS actions</th>
                          <td>{number_of_hymas_actions.value}</td>
                        </tr>
                        <tr>
                          <th>Number of Coils</th>
                          <td>{number_of_coils.value}</td>
                        </tr>
                        <tr>
                          <th>Delay Statistics</th>
                          <td>
                            <ListGroup variant="flush">
                              <ListGroup.Item>
                                Count: {delay_stats.count}
                              </ListGroup.Item>
                              <ListGroup.Item>
                                Min: {delay_stats.min}
                              </ListGroup.Item>
                              <ListGroup.Item>
                                Max: {delay_stats.max}
                              </ListGroup.Item>
                              <ListGroup.Item>
                                Avarage: {delay_stats.avg}
                              </ListGroup.Item>
                              <ListGroup.Item>
                                Sum: {delay_stats.sum}
                              </ListGroup.Item>
                            </ListGroup>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    {/* <pre>{JSON.stringify(args.aggregations, null, 1)}</pre> */}
                  </div>
                );
              }}
              react={{ and: ['overview_refine_event_time'] }}
            />
          </Col>
          <Col>
            <Row>
              <Col>
                <ReactiveComponent
                  componentId="overview_hymas_actions"
                  defaultQuery={() => ({
                    size: 0,
                    aggs: {
                      hymas_actions_amount: {
                        cardinality: {
                          field: 'hymas_action_no',
                        },
                      },
                      hymas_actions_overview: {
                        terms: {
                          field: 'hymas_action_desc.keyword',
                        },
                      },
                    },
                  })}
                  render={(args) => {
                    if (!args.aggregations) return null;

                    const { labels, series } = PieChartData(
                      args.aggregations.hymas_actions_overview.buckets
                    );

                    const options = PieOptions;
                    options.labels = labels;
                    options.title.text = 'Overall HyMAS Actions by Type';

                    return (
                      <div>
                        <Chart
                          type="pie"
                          series={series}
                          options={options}
                          width="500"
                          height="300"
                        />
                      </div>
                    );
                  }}
                  react={{ and: ['overview_refine_event_time'] }}
                />
              </Col>
              <Col>
                <ReactiveComponent
                  componentId="overview_mes_events"
                  defaultQuery={() => ({
                    size: 0,
                    aggs: {
                      mew_events_amount: {
                        cardinality: {
                          field: 'mes_event_no',
                        },
                      },
                      mes_events_overview: {
                        terms: {
                          field: 'mes_event_desc.keyword',
                        },
                      },
                    },
                  })}
                  render={(args) => {
                    if (!args.aggregations) return null;

                    const { labels, series } = PieChartData(
                      args.aggregations.mes_events_overview.buckets
                    );

                    const options = PieOptions;
                    options.labels = labels;
                    options.title.text = 'Overall MES Events';

                    return (
                      <div>
                        <Chart
                          type="pie"
                          series={series}
                          options={options}
                          width="500"
                          height="300"
                        />
                      </div>
                    );
                  }}
                  react={{ and: ['overview_refine_event_time'] }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DataAnalysisOverview;
