/* eslint-disable react/prop-types */
import React from 'react';
import { Tabs } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

function ControlledTabs({ param, defaultTab, children, ...rest }) {
  const { search } = useLocation();
  const [tab, setTab] = React.useState(
    new URLSearchParams(search).get(param) || defaultTab
  );

  React.useEffect(() => {
    if (tab) {
      const params = new URLSearchParams(search);
      params.set(param, tab);
      window.history.replaceState({}, 'TabSelect', `?${params.toString()}`);
      /*
        SM: navigate läd die Seite neu, das ist hier nicht nötig.
        react-router-dom useSearch läd auch alles neu, keine Option.
        window.history.replaceState tut das, was es soll 
      */
      // navigate(`?${params.toString()}`, { replace: true });
    }
  }, [tab, param, search]);

  return (
    <Tabs
      activeKey={tab}
      onSelect={setTab}
      transition={false}
      mountOnEnter
      unmountOnExit
      {...rest}
    >
      {children}
    </Tabs>
  );
}

export default ControlledTabs;
