/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import {
  DynamicRangeSlider,
  MultiDataList,
  ReactiveBase,
  ReactiveComponent,
} from '@appbaseio/reactivesearch';
import dateformat from 'dateformat';
import { get, map, sortBy, without } from 'lodash';
import React from 'react';
import { Col, Container, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { range10 } from '../components/Charts/colors';
import LineChartComponent from '../components/Charts/LineChartComponent';
import { useReactiveProps } from '../ReactiveProps';
import theme from './ReactiveBaseTheme';

const interactiveIds = [
  'refine_event_time',
  'refine_activity',
  'refine_mes_type',
];

const MES_EVENT_TYPE_MAPPING = [
  {
    label: 'Change in Width',
    value: '1',
  },
  {
    label: 'Change in Thickness',
    value: '2',
  },
  {
    label: 'New production order',
    value: '3',
  },
  {
    label: 'Daily new orders',
    value: '4',
  },
  {
    label: 'New material',
    value: '5',
  },
  {
    label: 'Parameters of coil changed',
    value: '9',
  },
  {
    label: 'Rush order',
    value: '10',
  },
  {
    label: 'Single new order',
    value: '12',
  },
];

const HYMAS_TYPE_MAPPING = [
  {
    label: 'Assess schedule before event',
    value: '1',
  },
  {
    label: 'Assess schedule after event',
    value: '2',
  },
  {
    label: 'Assess best HyMAS suggestion',
    value: '3',
  },
  {
    label: 'Assessment of MES decision',
    value: '4',
  },
  {
    label: 'Assess after realization - end of day',
    value: '5',
  },
  {
    label: 'Assess after realization - one day after',
    value: '6',
  },
];

const ChartDiffKosten = React.memo(({ aggregations }) => {
  if (!aggregations) return null;
  const buckets = aggregations['mes_type'].buckets;
  const keys = sortBy(
    without(buckets.length ? Object.keys(buckets[0]) : [], 'key', 'doc_count')
  );
  const data = buckets.map((b) => {
    // console.log(b);
    return {
      key:
        MES_EVENT_TYPE_MAPPING.find((m) => m.value === b.key)?.label || b.key,
      diff12: b.diff12?.value,
      diff13: b.diff13?.value,
      diff14: b.diff14?.value,
    };
  });
  return (
    <>
      <ResponsiveContainer width="100%" aspect={3}>
        <BarChart
          data={data}
          isAnimationActive={false}
          margin={{
            top: 5,
            right: 20,
            left: 20,
            bottom: 5,
          }}
          width={500}
        >
          <CartesianGrid vertical={false} />
          <XAxis dataKey="key" />
          <YAxis />
          <Tooltip isAnimationActive={false} />
          <Legend />
          {keys.map((k, i) => (
            <Bar
              key={k}
              dataKey={k}
              fill={range10[i]}
              isAnimationActive={false}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
});

const ChartEventKosten = React.memo(({ aggregations }) => {
  if (!aggregations) return null;
  const mapping = aggregations.timestamp.buckets.map((ts) => {
    const c = {
      key: dateformat(ts['key'], 'dd.mm.yyyy'),
    };
    ts.eventtype.buckets.forEach((e) => {
      c[e.key] = Math.round(e.cost.value * 100) / 100;
    });
    return c;
  });
  return (
    <LineChartComponent
      aspect={4}
      data={mapping}
      xaxis={[
        {
          dataKey: 'Assess schedule before event',
          stroke: 'black',
        },
        {
          dataKey: 'Assess schedule after event',
          stroke: 'orange',
        },
        {
          dataKey: 'Assess best HyMAS suggestion',
          stroke: 'darkgreen',
        },
        {
          dataKey: 'Assessment of MES decision',
          stroke: 'blue',
        },
        {
          dataKey: 'Assess after realization - end of day',
          stroke: 'red',
        },
        {
          dataKey: 'Assess after realization - one day after',
          stroke: 'darkred',
        },
      ]}
      yaxis={{
        type: 'number',
        name: 'Costs',
        unit: '',
        label: {
          value: '',
          angle: -90,
          position: 'insideBottomLeft',
        },
      }}
    />
  );
});

const ChartEventDelay = React.memo(({ aggregations }) => {
  if (!aggregations) return null;
  const mapping = aggregations.timestamp.buckets.map((ts) => {
    const c = {
      key: dateformat(ts['key'], 'dd.mm.yyyy'),
    };
    ts.eventtype.buckets.forEach((e) => {
      c[e.key] = Math.round(e.cost.value * 100) / 100;
    });
    return c;
  });
  return (
    <LineChartComponent
      aspect={4}
      data={mapping}
      xaxis={[
        {
          dataKey: 'Assess schedule before event',
          stroke: 'black',
        },
        {
          dataKey: 'Assess schedule after event',
          stroke: 'orange',
        },
        {
          dataKey: 'Assess best HyMAS suggestion',
          stroke: 'darkgreen',
        },
        {
          dataKey: 'Assessment of MES decision',
          stroke: 'blue',
        },
        {
          dataKey: 'Assess after realization - end of day',
          stroke: 'red',
        },
        {
          dataKey: 'Assess after realization - one day after',
          stroke: 'darkred',
        },
      ]}
      yaxis={{
        type: 'number',
        name: 'Delay',
        label: {
          value: '',
          angle: -90,
          position: 'insideBottomLeft',
        },
      }}
    />
  );
});

export default function Analyics(props) {
  const reactiveProps = useReactiveProps('hymas_src_analytics_hymas_events');
  if (!reactiveProps) return <div />;
  
  return (
    <ReactiveBase {...reactiveProps} theme={theme}>
      <div className="container-fluid my-2">
        <div className="row g-2">
          <div className="col-3">
            <div className="p-1 bg-white">
              <DynamicRangeSlider
                componentId="refine_event_time"
                dataField="timestamp"
                title="Event Time"
                showHistogram={true}
                loader={<Spinner animation="border" size="sm" />}
                rangeLabels={(min, max) => {
                  return {
                    start: dateformat(min, 'dd.mm.yyyy'),
                    end: dateformat(max, 'dd.mm.yyyy'),
                  };
                }}
              />
              <hr />
              <MultiDataList
                componentId="refine_mes_type"
                dataField="mes_event_type.keyword"
                data={MES_EVENT_TYPE_MAPPING}
                title="MES Event Type"
                showCount
                showFilter
                showSearch={false}
                loader={<Spinner animation="border" size="sm" />}
                URLParams
              />
              <hr />
              <MultiDataList
                componentId="refine_activity"
                dataField="hymas_type_id.keyword"
                data={HYMAS_TYPE_MAPPING}
                title="HyMAS Activity"
                showCount
                showFilter
                showSearch={false}
                react={{
                  and: [...without(interactiveIds, 'refine_activity')],
                }}
                loader={<Spinner animation="border" size="sm" />}
                URLParams
              />
              <hr />
            </div>
          </div>
          <div className="col-9">
            <div className="p-1 bg-white">
              <Tabs defaultActiveKey="charts" className="mb-2">
                <Tab eventKey="charts" title="Charts">
                  <Container className="mt-2">
                    <Row className="g-2">
                      <Col className="col-2">
                        <div className="p-1 bg-white">
                          <div>Average Duration / MES Event Type</div>
                        </div>
                      </Col>
                      <Col className="col-10">
                        <div className="p-1 bg-white">
                          <ReactiveComponent
                            componentId="chart_analytics3"
                            defaultQuery={() => ({
                              aggs: {
                                data: {
                                  terms: { field: 'mes_event_type.keyword' },
                                  aggs: {
                                    data: {
                                      avg: { field: 'mes_event_duration' },
                                    },
                                  },
                                },
                              },
                              size: 0,
                            })}
                            render={({ aggregations, ...rest }) => {
                              const data = map(
                                get(aggregations, 'data.buckets'),
                                (b) => {
                                  const diff = Math.round(b.data.value);
                                  return {
                                    key:
                                      MES_EVENT_TYPE_MAPPING.find(
                                        (m) => m.value === b.key
                                      )?.label || b.key,
                                    value: diff,
                                  };
                                }
                              );
                              return (
                                <ResponsiveContainer width="100%" aspect={3}>
                                  <BarChart
                                    data={data}
                                    isAnimationActive={false}
                                  >
                                    <XAxis dataKey="key" />
                                    <YAxis dataKey="value" unit={'ms'} />
                                    <Bar
                                      dataKey="value"
                                      name="AVG Duration in ms"
                                      fill={'orange'}
                                      isAnimationActive={false}
                                    />
                                    <Tooltip isAnimationActive={false} />
                                  </BarChart>
                                </ResponsiveContainer>
                              );
                            }}
                          ></ReactiveComponent>
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-2">
                      <Col className="col-2">
                        <div className="p-1 bg-white">
                          <div>Stringer / Day / Event Type</div>
                        </div>
                      </Col>
                      <Col className="col-10">
                        <div className="p-1 bg-white">
                          <ReactiveComponent
                            react={{
                              and: [
                                ...without(interactiveIds, 'refine_mes_type'),
                              ],
                            }}
                            componentId="chart_analytics1"
                            defaultQuery={() => ({
                              aggs: {
                                timestamp: {
                                  date_histogram: {
                                    field: 'timestamp',
                                    interval: '1d',
                                  },
                                  aggs: {
                                    eventtype: {
                                      terms: { field: 'activity.keyword' },
                                      aggs: {
                                        cost: {
                                          avg: { field: 'stringer' },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                              size: 0,
                            })}
                            render={(props) => <ChartEventKosten {...props} />}
                          ></ReactiveComponent>
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-2">
                      <Col className="col-2">
                        <div className="p-1 bg-white">
                          <div>
                            Average Delay / Day / Event Type
                            {/* Durchschnittliche Kosten pro Tag und Event-Typ */}
                          </div>
                        </div>
                      </Col>
                      <Col className="col-10">
                        <div className="p-1 bg-white">
                          <ReactiveComponent
                            react={{
                              and: [
                                ...without(interactiveIds, 'refine_mes_type'),
                              ],
                            }}
                            componentId="chart_analytics2"
                            defaultQuery={() => ({
                              aggs: {
                                timestamp: {
                                  date_histogram: {
                                    field: 'timestamp',
                                    interval: '1d',
                                  },
                                  aggs: {
                                    eventtype: {
                                      terms: { field: 'activity.keyword' },
                                      aggs: {
                                        cost: {
                                          avg: { field: 'delay' },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                              size: 0,
                            })}
                            render={(props) => <ChartEventDelay {...props} />}
                          ></ReactiveComponent>
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-2">
                      <Col className="col-2">
                        <div className="p-1 bg-white">
                          <div>Delay / Event Type</div>
                        </div>
                      </Col>
                      <Col className="col-10">
                        <div className="p-1 bg-white">
                          <ReactiveComponent
                            componentId="chart_delay"
                            defaultQuery={() => ({
                              aggs: {
                                mes_type: {
                                  terms: {
                                    field: 'mes_event_type.keyword',
                                    order: { _key: 'asc' },
                                  },
                                  aggs: {
                                    diff12: {
                                      avg: { field: 'diff_d_1_2' },
                                    },
                                    diff13: {
                                      avg: { field: 'diff_d_1_3' },
                                    },
                                    diff14: {
                                      avg: { field: 'diff_d_1_4' },
                                    },
                                  },
                                },
                              },
                              size: 0,
                            })}
                            render={(props) => <ChartDiffKosten {...props} />}
                          ></ReactiveComponent>
                        </div>
                      </Col>
                    </Row>
                    <Row className="g-2">
                      <Col className="col-2">
                        <div className="p-1 bg-white">
                          <div>Stringer / Event Type</div>
                        </div>
                      </Col>
                      <Col className="col-10">
                        <div className="p-1 bg-white">
                          <ReactiveComponent
                            componentId="chart_kosten"
                            defaultQuery={() => ({
                              aggs: {
                                mes_type: {
                                  terms: {
                                    field: 'mes_event_type.keyword',
                                    order: { _key: 'asc' },
                                  },
                                  aggs: {
                                    diff12: {
                                      avg: { field: 'diff_sc_1_2' },
                                    },
                                    diff13: {
                                      avg: { field: 'diff_sc_1_3' },
                                    },
                                    diff14: {
                                      avg: { field: 'diff_sc_1_4' },
                                    },
                                  },
                                },
                              },
                              size: 0,
                            })}
                            render={(props) => <ChartDiffKosten {...props} />}
                          ></ReactiveComponent>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Tab>
                <Tab
                  eventKey="searchresult"
                  title="Search Result"
                  disabled
                ></Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </ReactiveBase>
  );
}
