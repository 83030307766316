/* eslint-disable react/prop-types */
import {
  DynamicRangeSlider,
  MultiList,
  ReactiveComponent,
  SelectedFilters,
} from '@appbaseio/reactivesearch';
import dateformat from 'dateformat';
import React from 'react';
import Chart from 'react-apexcharts';
import { Col, Row, Spinner } from 'react-bootstrap';
import MyResponsiveSunburst from '../components/Charts/Sunburst2';
import { range10 } from './colors';

const HyMAS_per_MES_Chart = ({ aggregations }) => {
  const mesBuckets = aggregations?.group_by_mes.buckets;
  const series = [];
  const dataMap = new Map();
  const categories = mesBuckets ? mesBuckets.map((b) => b.key) : [];

  mesBuckets.map((mb) => {
    mb.related_hymas.buckets.map((rh) => {
      if (!dataMap.has(rh.key)) {
        dataMap.set(rh.key, Array(categories.length).fill(0));
      }
      const data = dataMap.get(rh.key);
      const cIndex = categories.indexOf(mb.key);
      data[cIndex] = rh.doc_count;
    });
  });

  dataMap.forEach((value, key) => series.push({ name: key, data: value }));

  const options = {
    chart: {
      type: 'bar',
      stacked: true,
    },
    colors: range10,
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    xaxis: {
      categories,
    },
    yaxis: {
      title: {
        text: 'MES Events',
      },
      labels: {
        minWidth: 200,
        maxWidth: 200,
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
    },
  };

  return <Chart series={series} options={options} type="bar" height={350} />;
};

const MES_per_HyMAS_Chart = ({ aggregations }) => {
  const hymasBuckets = aggregations?.group_by_hymas.buckets;
  const series = [];
  const dataMap = new Map();
  const categories = hymasBuckets ? hymasBuckets.map((b) => b.key) : [];

  hymasBuckets.map((hb) => {
    hb.related_mes.buckets.map((rm) => {
      if (!dataMap.has(rm.key)) {
        dataMap.set(rm.key, Array(categories.length).fill(0));
      }
      const data = dataMap.get(rm.key);
      const cIndex = categories.indexOf(hb.key);
      data[cIndex] = rm.doc_count;
    });
  });

  dataMap.forEach((value, key) => series.push({ name: key, data: value }));

  const options = {
    chart: {
      type: 'bar',
      stacked: true,
    },
    colors: range10,
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    xaxis: {
      categories,
    },
    yaxis: {
      title: {
        text: 'HyMAS Actions',
      },
      labels: {
        minWidth: 200,
        maxWidth: 200,
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
    },
  };

  return <Chart series={series} options={options} type="bar" height={350} />;
};

const bucketsToSunburst = (buckets, chartName) => {
  const series = {
    name: chartName,
    children: [],
  };
  buckets.forEach((mb) => {
    const me = { name: mb.key, children: [] };
    series.children.push(me);
    mb.related.buckets.forEach((rh) => {
      me.children.push({
        name: `${mb.key}__${rh.key}`,
        value: rh.doc_count,
      });
    });
  });

  return series;
};

const DataAnalysisRelationships = () => {
  return (
    <Row>
      <Col xs={3} className="p-2">
        <SelectedFilters />
        <DynamicRangeSlider
          componentId="refine_event_time"
          dataField="event_time"
          title="Event Date"
          showHistogram={true}
          queryFormat="date"
          calendarInterval="day"
          innerClass={{
            title: 'fw-bold',
          }}
          loader={<Spinner animation="border" size="sm" />}
          rangeLabels={(min, max) => {
            return {
              start: dateformat(min, 'dd.mm.yyyy'),
              end: dateformat(max, 'dd.mm.yyyy'),
            };
          }}
          className="mt-1"
        />
        <hr />
        <MultiList
          componentId="refine_mes_type"
          dataField="mes_event_desc.keyword"
          title="MES Event Type"
          showCount
          showFilter
          showSearch={false}
          loader={<Spinner animation="border" size="sm" />}
          URLParams
          innerClass={{
            title: 'fw-bold',
          }}
          className="mt-1"
        />
        <MultiList
          componentId="refine_hymas_type"
          dataField="hymas_action_desc.keyword"
          title="HyMAS Action"
          showCount
          showFilter
          showSearch={false}
          loader={<Spinner animation="border" size="sm" />}
          URLParams
          innerClass={{
            title: 'fw-bold',
          }}
          className="mt-1"
        />
        <DynamicRangeSlider
          componentId="refine_no_of_coils"
          dataField="number_of_coils_in_schedule"
          title="No of Coils"
          showFilter
          loader={<Spinner animation="border" size="sm" />}
          URLParams
          innerClass={{
            title: 'fw-bold',
          }}
          includeNullValues={false}
          tooltipTrigger="hover"
          showHistogram={true}
          className="mt-1"
        />
      </Col>
      <Col>
        <Row>
          <Col>
            <ReactiveComponent
              componentId="mes_to_hymas_chart"
              defaultQuery={() => ({
                size: 0,
                aggs: {
                  group_by_mes: {
                    terms: {
                      field: 'mes_event_desc.keyword',
                      size: 100,
                    },
                    aggs: {
                      related_hymas: {
                        terms: {
                          field: 'hymas_action_desc.keyword',
                          size: 100,
                        },
                      },
                    },
                  },
                },
              })}
              render={({ aggregations, isLoading, error }) => {
                if (isLoading) return null;
                if (error) return <div>Error:&nbsp;{error.message}</div>;
                if (aggregations) {
                  return <HyMAS_per_MES_Chart aggregations={aggregations} />;
                }
                return null;
              }}
              react={{
                and: [
                  'refine_event_time',
                  'refine_mes_type',
                  'refine_hymas_type',
                  'refine_no_of_stringers',
                  'refine_no_of_coils',
                ],
              }}
            />
          </Col>
          <Col>
            <ReactiveComponent
              componentId="mes_to_hymas_sunburstchart"
              defaultQuery={() => ({
                size: 0,
                aggs: {
                  group_by_mes: {
                    terms: {
                      field: 'mes_event_desc.keyword',
                      size: 100,
                    },
                    aggs: {
                      related: {
                        terms: {
                          field: 'hymas_action_desc.keyword',
                          size: 100,
                        },
                      },
                    },
                  },
                },
              })}
              render={({ aggregations, isLoading, error }) => {
                if (isLoading) return null;
                if (error) return <div>Error:&nbsp;{error.message}</div>;
                if (aggregations) {
                  return (
                    <MyResponsiveSunburst
                      data={bucketsToSunburst(
                        aggregations?.group_by_mes.buckets,
                        'MES → HyMAS'
                      )}
                    />
                  );
                }
                return null;
              }}
              react={{
                and: [
                  'refine_event_time',
                  'refine_mes_type',
                  'refine_hymas_type',
                  'refine_no_of_stringers',
                  'refine_no_of_coils',
                ],
              }}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <ReactiveComponent
              componentId="hymas_to_mes_chart"
              defaultQuery={() => ({
                size: 0,
                aggs: {
                  group_by_hymas: {
                    terms: {
                      field: 'hymas_action_desc.keyword',
                      size: 100,
                    },
                    aggs: {
                      related_mes: {
                        terms: {
                          field: 'mes_event_desc.keyword',
                        },
                      },
                    },
                  },
                },
              })}
              render={({ aggregations, isLoading, error }) => {
                if (isLoading) return null;
                if (error) return <div>Error:&nbsp;{error.message}</div>;
                if (aggregations) {
                  return <MES_per_HyMAS_Chart aggregations={aggregations} />;
                }
                return null;
              }}
              react={{
                and: [
                  'refine_event_time',
                  'refine_mes_type',
                  'refine_hymas_type',
                  'refine_no_of_stringers',
                  'refine_no_of_coils',
                ],
              }}
            />
          </Col>
          <Col>
            <ReactiveComponent
              componentId="hymas_to_mes_sunburstchart"
              defaultQuery={() => ({
                size: 0,
                aggs: {
                  group_by_hymas: {
                    terms: {
                      field: 'hymas_action_desc.keyword',
                      size: 100,
                    },
                    aggs: {
                      related: {
                        terms: {
                          field: 'mes_event_desc.keyword',
                        },
                      },
                    },
                  },
                },
              })}
              render={({ aggregations, isLoading, error }) => {
                if (isLoading) return null;
                if (error) return <div>Error:&nbsp;{error.message}</div>;
                if (aggregations) {
                  return (
                    <MyResponsiveSunburst
                      data={bucketsToSunburst(
                        aggregations?.group_by_hymas.buckets,
                        'HyMAS → MES'
                      )}
                    />
                  );
                }
                return null;
              }}
              react={{
                and: [
                  'refine_event_time',
                  'refine_mes_type',
                  'refine_hymas_type',
                  'refine_no_of_stringers',
                  'refine_no_of_coils',
                ],
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DataAnalysisRelationships;
