/* eslint-disable react/prop-types */
import {
  DateRange,
  DynamicRangeSlider,
  ReactiveComponent,
  MultiList
} from '@appbaseio/reactivesearch';
import React, { useEffect, useState } from 'react';
import dateformat from 'dateformat';
import Chart from 'react-apexcharts';
import { Col, Container, Row, Spinner } from 'react-bootstrap';

const valueFormatter = (val) => {
  switch (val) {
  case -5:
    return 'No solution found';
  case -4:
    return '';
  case -3:
    return 'Local optimization (by PA request)';
  case -2:
    return 'Local optimization (single CAL)';
  case -1:
    return 'PDI adjustment';
  case 0:
    return 'No action needed';
  case 1:
    return 'Deviating temperature';
  case 2:
    return 'Defect coil';
  case 3:
    return '';
  case 4:
    return '';
  case 5:
    return 'Urgent material';
  case 6:
    return '';
  }
  return val;
};

const scheduleTimeChartOptions = {
  chart: {
    stacked: false,
  },
  plotOptions: {},
  dataLabels: {
    enabled: false,
  },
  grid: {
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
  },
  xaxis: {
    type: 'datetime',
  },
  yaxis: {
    min: -4,
    max: 6,
    tickAmount: 10,
    labels: {
      minWidth: 200,
      maxWidth: 200,
      formatter: valueFormatter,
    },
  },
  tooltip: {
    shared: false,
    x: {
      formatter: function (val) {
        return dateformat(val, 'dd.mm.yyyy HH:mm:ss');
      },
    },
    y: {
      formatter: valueFormatter,
    },
  },
};

class MES_vs_HYMAS_Bubble extends React.Component {
  render() {
    return null;
  }
}

const ScheduleCharts = ({ data }) => {
  const [hymas, setHymas] = useState();
  useEffect(() => {
    if (data) {
      const hymasData = {
        series: [
          {
            name: 'MES Event',
            data: data.map((hit) => [
              new Date(hit.event_time).getTime(),
              hit.mes_event_no,
            ]),
          },
          {
            name: 'HyMAS Action',
            data: data.map((hit) => [
              new Date(hit.event_time).getTime(),
              hit.hymas_action_no * -1,
            ]),
          },
        ],
      };
      setHymas(hymasData);
    }
  }, [data]);
  // console.log(hymas?.series);
  if (!hymas) return null;

  return (
    <>
      <Chart
        options={scheduleTimeChartOptions}
        series={hymas.series}
        type="scatter"
        height={500}
        /* width={800} */
      />
      <MES_vs_HYMAS_Bubble />
    </>
  );
};

const DataAnalysisSchedule = () => {
  return (
    <Row>
      <Col className="col-2 p-2">
        <Container>
          <Row>
            <Col>
              <DynamicRangeSlider
                componentId="schedule_refine_event_time"
                dataField="event_time"
                title="Event Date"
                showHistogram={true}
                queryFormat="date"
                calendarInterval="day"
                innerClass={{
                  title: 'fw-bold',
                }}
                loader={<Spinner animation="border" size="sm" />}
                rangeLabels={(min, max) => {
                  return {
                    start: dateformat(min, 'dd.mm.yyyy'),
                    end: dateformat(max, 'dd.mm.yyyy'),
                  };
                }}
                className="mt-1"
              />
              <hr />
              <DateRange
                componentId="schedule_datefilter"
                URLParams
                showFilter
                dataField="event_time"
              />
              <br />
              <MultiList
                componentId="refine_mes_type"
                dataField="mes_event_desc.keyword"
                title="MES Event Type"
                showCount
                showFilter
                showSearch={false}
                loader={<Spinner animation="border" size="sm" />}
                URLParams
                innerClass={{
                  title: 'fw-bold',
                }}
                className="mt-1"
              />
              <MultiList
                componentId="refine_hymas_type"
                dataField="hymas_action_desc.keyword"
                title="HyMAS Action"
                showCount
                showFilter
                showSearch={false}
                loader={<Spinner animation="border" size="sm" />}
                URLParams
                innerClass={{
                  title: 'fw-bold',
                }}
                className="mt-1"
              />
            </Col>
          </Row>
        </Container>
      </Col>
      <Col className="col-8">
        <ReactiveComponent
          componentId="schedule_charts"
          defaultQuery={() => ({
            sort: [{ event_time: 'asc' }],
            size: 1000,
          })}
          render={({ data }) => {
            if (!data || data.length === 0) return null;
            return <ScheduleCharts data={data} />;
          }}
          react={{
            and: ['schedule_datefilter', 'schedule_refine_event_time','refine_mes_type','refine_hymas_type'],
          }}
        />
      </Col>
    </Row>
  );
};

export default DataAnalysisSchedule;
