/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { ReactiveBase } from '@appbaseio/reactivesearch';
import React from 'react';
import { Col, Container, Row, Tab } from 'react-bootstrap';
import ControlledTabs from '../components/ControlledTabs.jsx';
import {
  DataAnalysisOverview,
  DataAnalysisRelationships,
  DataAnalysisSchedule
} from '../groups';
import DataAnalysisPCC from '../groups/DataAnalysisPCC.jsx';
import { useReactiveProps } from '../ReactiveProps.jsx';
import theme from './ReactiveBaseTheme';

export default function Charts() {
  const reactiveProps = useReactiveProps('hymas_data_analytics_mockup');
  if (!reactiveProps) return <div />;

  return (
    <ReactiveBase {...reactiveProps} theme={theme}>
      <Container fluid>
        <Row>
          <Col>
            <ControlledTabs param="tab" defaultTab="overview">
              <Tab eventKey="overview" title="Overview">
                <DataAnalysisOverview />
              </Tab>
              <Tab eventKey="schedule" title="Schedule">
                <DataAnalysisSchedule />
              </Tab>
              <Tab eventKey="mes_to_hymas" title="MES events / HyMAS actions">
                <DataAnalysisRelationships />
              </Tab>
              <Tab eventKey="pcc" title="Parallel Coordinates Chart">
                <DataAnalysisPCC />
              </Tab>
            </ControlledTabs>
          </Col>
        </Row>
      </Container>
    </ReactiveBase>
  );
}
