/* eslint-disable react/prop-types */
import {
  DateRange,
  DynamicRangeSlider,
  ErrorBoundary,
  MultiList,
  ReactiveBase,
  ReactiveComponent,
  ReactiveList,
  ResultList,
  SelectedFilters,
} from '@appbaseio/reactivesearch';
import dateformat from 'dateformat';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Col, Container, Row, Spinner, Tab } from 'react-bootstrap';
import { useReactiveProps } from '../ReactiveProps';
import { PieChartData, PieOptions } from '../components/Charts/ApexChartsUtl';
import MyResponsiveSunburst from '../components/Charts/Sunburst2';
import ControlledTabs from '../components/ControlledTabs';
import Source from '../components/Develop/Source';
import theme from './ReactiveBaseTheme';
import { range10 } from '../groups/colors';

const valueFormatter = (val) => {
  switch (val) {
  case -5:
    return 'No solution found';
  case -4:
    return '';
  case -3:
    return 'Local optimization (by PA request)';
  case -2:
    return 'Local optimization (single CAL)';
  case -1:
    return 'PDI adjustment';
  case 0:
    return 'No action needed';
  case 1:
    return 'Deviating temperature';
  case 2:
    return 'Defect coil';
  case 3:
    return '';
  case 4:
    return '';
  case 5:
    return 'Urgent material';
  case 6:
    return '';
  }
  return val;
};

const scheduleTimeChartOptions = {
  chart: {
    stacked: false,
  },
  plotOptions: {},
  dataLabels: {
    enabled: false,
  },
  grid: {
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
  },
  xaxis: {
    type: 'datetime',
  },
  yaxis: {
    min: -4,
    max: 6,
    tickAmount: 10,
    labels: {
      minWidth: 200,
      maxWidth: 200,
      formatter: valueFormatter,
    },
  },
  tooltip: {
    shared: false,
    x: {
      formatter: function (val) {
        return dateformat(val, 'dd.mm.yyyy HH:mm:ss');
      },
    },
    y: {
      formatter: valueFormatter,
    },
  },
};

const ScheduleCharts = ({ data }) => {
  const [hymas, setHymas] = useState();
  useEffect(() => {
    if (data) {
      const hymasData = {
        series: [
          {
            name: 'MES Event',
            data: data.map((hit) => [
              new Date(hit.timeStamp).getTime(),
              hit.mesEventNo,
            ]),
          },
          {
            name: 'HyMAS Action',
            data: data.map((hit) => [
              new Date(hit.timeStamp).getTime(),
              hit.hymasActionName * -1,
            ]),
          },
        ],
      };
      setHymas(hymasData);
    }
  }, [data]);

  if (!hymas) return null;

  return (
    <>
      <Chart
        options={scheduleTimeChartOptions}
        series={hymas.series}
        type="scatter"
        height={500}
        /* width={800} */
      />
    </>
  );
};

const HyMAS_per_MES_Chart = ({ aggregations }) => {
  const mesBuckets = aggregations?.group_by_mes.buckets;
  const series = [];
  const dataMap = new Map();
  const categories = mesBuckets ? mesBuckets.map((b) => b.key) : [];

  mesBuckets.map((mb) => {
    mb.related_hymas.buckets.map((rh) => {
      if (!dataMap.has(rh.key)) {
        dataMap.set(rh.key, Array(categories.length).fill(0));
      }
      const data = dataMap.get(rh.key);
      const cIndex = categories.indexOf(mb.key);
      data[cIndex] = rh.doc_count;
    });
  });

  dataMap.forEach((value, key) => series.push({ name: key, data: value }));

  const options = {
    chart: {
      type: 'bar',
      stacked: true,
    },
    colors: range10,
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    xaxis: {
      categories,
    },
    yaxis: {
      title: {
        text: 'MES Events',
      },
      labels: {
        minWidth: 200,
        maxWidth: 200,
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
    },
  };

  return <Chart series={series} options={options} type="bar" height={350} />;
};

const MES_per_HyMAS_Chart = ({ aggregations }) => {
  const hymasBuckets = aggregations?.group_by_hymas.buckets;
  const series = [];
  const dataMap = new Map();
  const categories = hymasBuckets ? hymasBuckets.map((b) => b.key) : [];

  hymasBuckets.map((hb) => {
    hb.related_mes.buckets.map((rm) => {
      if (!dataMap.has(rm.key)) {
        dataMap.set(rm.key, Array(categories.length).fill(0));
      }
      const data = dataMap.get(rm.key);
      const cIndex = categories.indexOf(hb.key);
      data[cIndex] = rm.doc_count;
    });
  });

  dataMap.forEach((value, key) => series.push({ name: key, data: value }));

  const options = {
    chart: {
      type: 'bar',
      stacked: true,
    },
    colors: range10,
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    xaxis: {
      categories,
    },
    yaxis: {
      title: {
        text: 'HyMAS Actions',
      },
      labels: {
        minWidth: 200,
        maxWidth: 200,
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
    },
  };

  return <Chart series={series} options={options} type="bar" height={350} />;
};

const bucketsToSunburst = (buckets, chartName) => {
  const series = {
    name: chartName,
    children: [],
  };
  buckets.forEach((mb) => {
    const me = { name: mb.key, children: [] };
    series.children.push(me);
    mb.related.buckets.forEach((rh) => {
      me.children.push({
        name: `${mb.key}__${rh.key}`,
        value: rh.doc_count,
      });
    });
  });

  return series;
};

export default function Dailabs() {
  const reactiveProps = useReactiveProps('hymas_src_dai');
  if (!reactiveProps) return <div />;

  return (
    <ReactiveBase {...reactiveProps} theme={theme}>
      <Container fluid>
        <Row>
          <Col>
            <ControlledTabs param="tab" defaultTab="overview">
              <Tab eventKey="overview" title="Overview">
                <Row>
                  <Col>
                    <ReactiveComponent
                      componentId={'stringer_per_mes'}
                      defaultQuery={() => ({
                        size: 0,
                        aggs: {
                          mes_events: {
                            terms: {
                              field: 'mesEventDescriptor.keyword',
                              order: { _key: 'asc' },
                            },
                            aggs: {
                              material: {
                                sum: {
                                  field: 'numberMaterials',
                                },
                              },
                            },
                          },
                        },
                      })}
                      render={({ aggregations }) => {
                        if (!aggregations) return null;
                        const { labels, series } = PieChartData(
                          aggregations.mes_events.buckets
                        );

                        const options = PieOptions;
                        options.xaxis = {
                          categories: labels,
                        };
                        options.title.text = 'Material / MES Event';
                        options.plotOptions = {
                          bar: {
                            horizontal: true,
                            dataLabels: {
                              position: 'top',
                            },
                          },
                        };

                        return (
                          <div>
                            <Chart
                              type="bar"
                              series={[{ data: [...series] }]}
                              options={options}
                              width="500"
                            />
                          </div>
                        );
                      }}
                    />
                  </Col>
                  <Col>
                    <ReactiveComponent
                      componentId={'stringer_per_hymas'}
                      defaultQuery={() => ({
                        size: 0,
                        aggs: {
                          hymas_actions: {
                            terms: {
                              field: 'hymasActionDescriptor.keyword',
                            },
                            aggs: {
                              material: {
                                sum: {
                                  field: 'numberMaterials',
                                },
                              },
                            },
                          },
                        },
                      })}
                      render={({ aggregations }) => {
                        if (!aggregations) return null;
                        const { labels, series } = PieChartData(
                          aggregations.hymas_actions.buckets
                        );

                        const options = PieOptions;
                        options.labels = labels;
                        options.title.text = 'Material / HyMAS Action';

                        return (
                          <div>
                            <Chart
                              type="pie"
                              series={series}
                              options={options}
                              width="500"
                              height="300"
                            />
                          </div>
                        );
                      }}
                    />
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="schedule" title="Schedule">
                <Row>
                  <Col className="col-2 p-2">
                    <Container>
                      <Row>
                        <Col>
                          <DynamicRangeSlider
                            componentId="schedule_refine_timeStamp"
                            dataField="timeStamp"
                            title="Event Date"
                            showHistogram={true}
                            queryFormat="date"
                            calendarInterval="week"
                            innerClass={{
                              title: 'fw-bold',
                            }}
                            loader={<Spinner animation="border" size="sm" />}
                            rangeLabels={(min, max) => {
                              return {
                                start: dateformat(min, 'dd.mm.yyyy'),
                                end: dateformat(max, 'dd.mm.yyyy'),
                              };
                            }}
                            className="mt-1"
                          />
                          <hr />
                          <DateRange
                            componentId="schedule_datefilter"
                            URLParams
                            showFilter
                            dataField="timeStamp"
                          />
                          <br />
                          <MultiList
                            componentId="refine_mes_type"
                            dataField="mesEventDescriptor.keyword"
                            title="MES Event Type"
                            showCount
                            showFilter
                            showSearch={false}
                            loader={<Spinner animation="border" size="sm" />}
                            URLParams
                            innerClass={{
                              title: 'fw-bold',
                            }}
                            className="mt-1"
                          />
                          <MultiList
                            componentId="refine_hymas_type"
                            dataField="hymasActionDescriptor.keyword"
                            title="HyMAS Action"
                            showCount
                            showFilter
                            showSearch={false}
                            loader={<Spinner animation="border" size="sm" />}
                            URLParams
                            innerClass={{
                              title: 'fw-bold',
                            }}
                            className="mt-1"
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col className="col-8">
                    <ReactiveComponent
                      componentId="schedule_charts"
                      defaultQuery={() => ({
                        sort: [{ timeStamp: 'asc' }],
                        size: 1000,
                      })}
                      render={({ data }) => {
                        if (!data || data.length === 0) return null;
                        return <ScheduleCharts data={data} />;
                      }}
                      react={{
                        and: [
                          'schedule_datefilter',
                          'schedule_refine_timeStamp',
                          'refine_mes_type',
                          'refine_hymas_type',
                        ],
                      }}
                    />
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="mes_to_hymas" title="MES events / HyMAS actions">
                <Row>
                  <Col xs={3} className="p-2">
                    <SelectedFilters />
                    <DynamicRangeSlider
                      componentId="refine_timeStamp"
                      dataField="timeStamp"
                      title="Event Date"
                      showHistogram={true}
                      queryFormat="date"
                      calendarInterval="day"
                      innerClass={{
                        title: 'fw-bold',
                      }}
                      loader={<Spinner animation="border" size="sm" />}
                      rangeLabels={(min, max) => {
                        return {
                          start: dateformat(min, 'dd.mm.yyyy'),
                          end: dateformat(max, 'dd.mm.yyyy'),
                        };
                      }}
                      className="mt-1"
                    />
                    <hr />
                    <MultiList
                      componentId="refine_mes_type"
                      dataField="mesEventDescriptor.keyword"
                      title="MES Event Type"
                      showCount
                      showFilter
                      showSearch={false}
                      loader={<Spinner animation="border" size="sm" />}
                      URLParams
                      innerClass={{
                        title: 'fw-bold',
                      }}
                      className="mt-1"
                    />
                    <MultiList
                      componentId="refine_hymas_type"
                      dataField="hymasActionDescriptor.keyword"
                      title="HyMAS Action"
                      showCount
                      showFilter
                      showSearch={false}
                      loader={<Spinner animation="border" size="sm" />}
                      URLParams
                      innerClass={{
                        title: 'fw-bold',
                      }}
                      className="mt-1"
                    />
                    <DynamicRangeSlider
                      componentId="refine_no_of_coils"
                      dataField="numberMaterials"
                      title="No of Coils"
                      showFilter
                      loader={<Spinner animation="border" size="sm" />}
                      URLParams
                      innerClass={{
                        title: 'fw-bold',
                      }}
                      includeNullValues={false}
                      tooltipTrigger="hover"
                      showHistogram={true}
                      className="mt-1"
                    />
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <ReactiveComponent
                          componentId="mes_to_hymas_chart"
                          defaultQuery={() => ({
                            size: 0,
                            aggs: {
                              group_by_mes: {
                                terms: {
                                  field: 'mesEventDescriptor.keyword',
                                  size: 100,
                                },
                                aggs: {
                                  related_hymas: {
                                    terms: {
                                      field: 'hymasActionDescriptor.keyword',
                                      size: 100,
                                    },
                                  },
                                },
                              },
                            },
                          })}
                          render={({ aggregations, isLoading, error }) => {
                            if (isLoading) return null;
                            if (error)
                              return <div>Error:&nbsp;{error.message}</div>;
                            if (aggregations) {
                              return (
                                <HyMAS_per_MES_Chart
                                  aggregations={aggregations}
                                />
                              );
                            }
                            return null;
                          }}
                          react={{
                            and: [
                              'refine_timeStamp',
                              'refine_mes_type',
                              'refine_hymas_type',
                              'refine_no_of_stringers',
                              'refine_no_of_coils',
                            ],
                          }}
                        />
                      </Col>
                      <Col>
                        <ReactiveComponent
                          componentId="mes_to_hymas_sunburstchart"
                          defaultQuery={() => ({
                            size: 0,
                            aggs: {
                              group_by_mes: {
                                terms: {
                                  field: 'mesEventDescriptor.keyword',
                                  size: 100,
                                },
                                aggs: {
                                  related: {
                                    terms: {
                                      field: 'hymasActionDescriptor.keyword',
                                      size: 100,
                                    },
                                  },
                                },
                              },
                            },
                          })}
                          render={({ aggregations, isLoading, error }) => {
                            if (isLoading) return null;
                            if (error)
                              return <div>Error:&nbsp;{error.message}</div>;
                            if (aggregations) {
                              return (
                                <MyResponsiveSunburst
                                  data={bucketsToSunburst(
                                    aggregations?.group_by_mes.buckets,
                                    'MES → HyMAS'
                                  )}
                                />
                              );
                            }
                            return null;
                          }}
                          react={{
                            and: [
                              'refine_timeStamp',
                              'refine_mes_type',
                              'refine_hymas_type',
                              'refine_no_of_stringers',
                              'refine_no_of_coils',
                            ],
                          }}
                        />
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <ReactiveComponent
                          componentId="hymas_to_mes_chart"
                          defaultQuery={() => ({
                            size: 0,
                            aggs: {
                              group_by_hymas: {
                                terms: {
                                  field: 'hymasActionDescriptor.keyword',
                                  size: 100,
                                },
                                aggs: {
                                  related_mes: {
                                    terms: {
                                      field: 'mesEventDescriptor.keyword',
                                    },
                                  },
                                },
                              },
                            },
                          })}
                          render={({ aggregations, isLoading, error }) => {
                            if (isLoading) return null;
                            if (error)
                              return <div>Error:&nbsp;{error.message}</div>;
                            if (aggregations) {
                              return (
                                <MES_per_HyMAS_Chart
                                  aggregations={aggregations}
                                />
                              );
                            }
                            return null;
                          }}
                          react={{
                            and: [
                              'refine_timeStamp',
                              'refine_mes_type',
                              'refine_hymas_type',
                              'refine_no_of_stringers',
                              'refine_no_of_coils',
                            ],
                          }}
                        />
                      </Col>
                      <Col>
                        <ReactiveComponent
                          componentId="hymas_to_mes_sunburstchart"
                          defaultQuery={() => ({
                            size: 0,
                            aggs: {
                              group_by_hymas: {
                                terms: {
                                  field: 'hymasActionDescriptor.keyword',
                                  size: 100,
                                },
                                aggs: {
                                  related: {
                                    terms: {
                                      field: 'mesEventDescriptor.keyword',
                                    },
                                  },
                                },
                              },
                            },
                          })}
                          render={({ aggregations, isLoading, error }) => {
                            if (isLoading) return null;
                            if (error)
                              return <div>Error:&nbsp;{error.message}</div>;
                            if (aggregations) {
                              return (
                                <MyResponsiveSunburst
                                  data={bucketsToSunburst(
                                    aggregations?.group_by_hymas.buckets,
                                    'HyMAS → MES'
                                  )}
                                />
                              );
                            }
                            return null;
                          }}
                          react={{
                            and: [
                              'refine_timeStamp',
                              'refine_mes_type',
                              'refine_hymas_type',
                              'refine_no_of_stringers',
                              'refine_no_of_coils',
                            ],
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="searchresult" title="Plain Data">
                <ErrorBoundary>
                  <ReactiveList
                    dataField="timeStamp"
                    componentId="searchresults"
                    pagination={true}
                    render={({ data }) => (
                      <div>
                        {data.map((item) => (
                          <ResultList key={item._id}>
                            <ResultList.Content>
                              <Source sourceObject={item} />
                            </ResultList.Content>
                          </ResultList>
                        ))}
                      </div>
                    )}
                  />
                </ErrorBoundary>
              </Tab>
            </ControlledTabs>
          </Col>
        </Row>
      </Container>
    </ReactiveBase>
  );
}
